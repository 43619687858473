import templateDay from './calendarday';

export default ({days, name}) => `
	<div class="phaeno-containerFull__text phaeno-textBlock">
		<h3 class="phaeno-textBlock__head">Termine im <strong>${name}</strong></h3>
	</div>

	${days.length ? `
		<ul class="phaeno-calendar__month js-phaeno-days-container">
			${days.map(day => templateDay({data: day})).join('')}
		</ul>
	`: `
		<p>Keine Termine</p>
	`}
`;
