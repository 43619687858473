export default ({data, linkTarget}) => `
	<li
		class="phaeno-calendar__day js-phaeno-calendar__day
			${data.isHoliday ? 'phaeno-calendar__day--holiday' : ''}
			${data.isActive ? 'phaeno-calendar__day--active' : ''}"
		data-date-string="${data.dateString}"
	>
		${linkTarget ? `<a href="${linkTarget}#calendar_${data.dateString}" class="js-phaeno-calendar__dayLink">` : ''}
			<div class="phaeno-calendar__dayContent">
				<div class="phaeno-calendar__leftColumn">
					<span class="phaeno-calendar__dayInfoDate">${data.date}</span>
					<span class="phaeno-calendar__dayInfoDay">${data.day}</span>
					<div class="phaeno-calendar__dayInfoDayFull">${data.dayFull}</div>
				</div>

				<div class="phaeno-calendar__rightColumn">
					<div class="phaeno-calendar__axeContainer">
						<ol class="phaeno-calendar__axe">
							<li class="phaeno-calendar__axeTick phaeno-calendar__axeTick--important"><p class="phaeno-calendar__axeTicktTxt">09:00</p></li><!--
							--><li class="phaeno-calendar__axeTick"><p class="phaeno-calendar__axeTicktTxt">10:00</p></li><!--
							--><li class="phaeno-calendar__axeTick"><p class="phaeno-calendar__axeTicktTxt">11:00</p></li><!--
							--><li class="phaeno-calendar__axeTick phaeno-calendar__axeTick--important"><p class="phaeno-calendar__axeTicktTxt">12:00</p></li><!--
							--><li class="phaeno-calendar__axeTick"><p class="phaeno-calendar__axeTicktTxt">13:00</p></li><!--
							--><li class="phaeno-calendar__axeTick"><p class="phaeno-calendar__axeTicktTxt">14:00</p></li><!--
							--><li class="phaeno-calendar__axeTick phaeno-calendar__axeTick--important"><p class="phaeno-calendar__axeTicktTxt">15:00</p></li><!--
							--><li class="phaeno-calendar__axeTick"><p class="phaeno-calendar__axeTicktTxt">16:00</p></li><!--
							--><li class="phaeno-calendar__axeTick"><p class="phaeno-calendar__axeTicktTxt">17:00</p></li><!--
							--><li class="phaeno-calendar__axeTick phaeno-calendar__axeTick--important"><p class="phaeno-calendar__axeTicktTxt">nach 18:00</p></li>
						</ol>
					</div>

					<ul class="phaeno-calendar__events">
						${data.isActive ? `
							<div class="phaeno-calendar__timeMarkContainer">
								<div class="phaeno-calendar__timeMark">
									<div class="phaeno-calendar__timeMarkLine js-phaeno-calendar__timeMarkLine"></div>
								</div>
							</div>
						` : ''}

						${data.events.map(mapEventTpl).join('')}
					</ul>
				</div>
			</div>
		${linkTarget ? '</a>' : ''}
	</li>
`;

function mapEventTpl(item) {
	switch (item.eventType) {
		case 'event':
			return eventTpl(item);
		case 'opening':
			return eventOpeningTpl(item);
		case 'label':
			return eventLabelTpl(item);
		default:
			return '';
	}
}

function eventTpl(data) {
	return `
		<li class="phaeno-calendar__event js-phaeno-calendar__event" data-eventid="${data.id}">
				${data.hours.map((item, index) => hoursTpl(item, data)).join('')}
		</li>
	`;
}

function eventOpeningTpl(data) {
	return `
		<li class="phaeno-calendar__event phaeno-calendar__event--open">
			${data.hours.map((item, index) => eventElementOpeningTpl(item, data)).join('')}
		</li>
	`;
}

function hoursTpl(item, data) {
	return `
		<div class="phaeno-calendar__eventElement js-phaeno-calendar__eventElement" data-start="${item.start}" data-end="${item.end}">
			<button class="phaeno-calendar__button js-phaeno-calendar__button">
				<p class="phaeno-calendar__eventHead">
					<span class="phaeno-calendar__eventHeadText">
						${data.name}
					</span>
					<span class="phaeno-calendar__eventHeadIcon">
						<svg><use xlink:href="#arrow_link_white"></use></svg>
					</span>
				</p>
			</button>
		</div>
	`;
}

function eventElementOpeningTpl(item, data) {
	return `
		<div class="phaeno-calendar__eventElement js-phaeno-calendar__eventElement" data-start="${item.start}" data-end="${item.end}">
			<p class="phaeno-calendar__eventHead">
				<span class="phaeno-calendar__eventHeadText">
					${data.name}:  ${formatTime(item.start)} - ${formatTime(item.end)} Uhr
				</span>
			</p>
		</div>
	`;
}

function eventLabelTpl(data) {
	return `
		<li class="phaeno-calendar__event phaeno-calendar__event--open">
			<div class="phaeno-calendar__additionalLabel">
				<p class="phaeno-calendar__additionalLabelHead">${data.name}</p>
			</div>
		</li>
	`;
}

function padTime(a) {
	return a < 10 ?  '0' + a : a;
}

function formatTime(dateStr) {
	const date = new Date(dateStr);
	return `${padTime(date.getHours())}:${padTime(date.getMinutes())}`;
}
