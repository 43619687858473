import {TweenMax} from 'gsap';

import variables from '../../utils/variables';
import getOffset from '../../utils/getOffset';
import scrollTo from '../../utils/scrollTo';

import TabMenu from './TabMenu';

class InfoTabMenu extends TabMenu {
	init() {
		super.init();
		this.contentContainer = this.container.querySelector('.js-phaeno-infoTabMenu__content');
		this.onResizeHandler = this.onResize.bind(this);
		window.addEventListener('resize', this.onResizeHandler);

		this.container.addEventListener(variables.events.POSSIBLE_HEIGHT_CHANGE, this.onPossibleHeightChange.bind(this));
	}

	onTab(e) {
		const id = e.delegateTarget.dataset.phaenoTrigger;
		if (this.currentId !== id) {
			const section = document.querySelector('#infotab');
			const top = getOffset(section).top;
			scrollTo(top);
		}
		super.onTab(e);
		this.setCurrentHeight();
	}

	onResize(e) {
		this.setCurrentHeight();
	}

	onPossibleHeightChange(e) {
		this.setCurrentHeight();
	}

	setCurrentHeight() {
		if (this.currentId === null) {
			this.setHeight(0);
		} else {
			const activeTab = this.getContentById(this.currentId);
			this.setHeight(activeTab.clientHeight);
		}
	}

	setHeight(h) {
		TweenMax.killTweensOf(this.contentContainer);
		TweenMax.to(this.contentContainer, variables.animDuration2, {height: h, ease: variables.animEaseOut, force3D: true});
	}
}

export default InfoTabMenu;
