import variables from '../../utils/variables';
import {range} from '../../utils/math-x';

class CalendarTimeLineMark {
	constructor() {
		this.markClass = '.js-phaeno-calendar__timeMarkLine';

		this.FROM = variables.calendar.from;
		this.TO = variables.calendar.to;
		this.DURATION = this.TO - this.FROM;
		this.HOURWIDTH = 100 / this.DURATION * 0.999;
	}

	init() {
		if (this.intervalId) {
			clearInterval(this.intervalId);
			this.intervalId = null;
		}

		this.position();
		this.intervalId = setInterval(this.position.bind(this), 1000 * 60);
	}

	position() {
		const now = new Date();
		const nowH = now.getHours();
		const nowM = now.getMinutes();
		let left = (nowH - this.FROM + nowM / 60 ) * this.HOURWIDTH;
		left = range(left, 0, 100);

		const marks = document.querySelectorAll(this.markClass);
		for (let i = 0; i < marks.length; i++) {
			const mark = marks[i];
			mark.style.left = left + '%';
		}
	}
}

export default CalendarTimeLineMark;
