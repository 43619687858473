class Video {
	constructor(container) {
		this.container = container;
		this.isPlaying = false;
		this.isInited = false;
	}

	init() {
		this.video = this.container.querySelector('.js-phaeno-video__video');
		this.playBtn = this.container.querySelector('.js-phaeno-video__play');

		if (this.video) {
			this.addListeners();
		}
		if (this.playBtn) {
			this.hidePlayBtn();
			this.showPlayBtn();
		}
	}

	addListeners() {
		this.listeners = [];
		this.listeners.push(this.video.addEventListener('playing', this.onPlaying.bind(this)));
		this.listeners.push(this.video.addEventListener('pause', this.onPause.bind(this)));
		this.listeners.push(this.video.addEventListener('ended', this.onEnded.bind(this)));

		this.listeners.push(this.video.addEventListener('canplaythrough', this.onCanPlayThrough.bind(this)));
		this.listeners.push(this.video.addEventListener('canplay', this.onCanPlayThrough.bind(this)));
		if (this.video.readyState >= 3) {
			this.onCanPlayThrough();
		}
	}

	onCanPlayThrough() {
		if (this.isInited === false) {
			this.video.addEventListener('click', this.onVideoClick.bind(this));
			if (this.playBtn) {
				this.playBtn.addEventListener('click', this.onVideoClick.bind(this));
			}
			this.isInited = true;
		}
	}

	onVideoClick(event) {
		if (this.isPlaying === true) {
			this.video.pause();
			this.showPlayBtn();
		} else {
			this.video.play();
			this.hidePlayBtn();
		}
	}

	onPlaying() {
		this.isPlaying = true;
	}

	onPause() {
		this.isPlaying = false;
	}

	onEnded() {
		this.isPlaying = false;
		this.showPlayBtn();
	}

	hidePlayBtn() {
		this.playBtn.style.display = 'none';
	}

	showPlayBtn() {
		this.playBtn.style.display = 'block';
	}
}

export default Video;
