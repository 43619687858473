import variables from '../../utils/variables';

import SliderMenu from './SliderMenu';
import SliderSlides from './SliderSlides';
import SliderDescriptions from './SliderDescriptions';
import SliderNextPrev from './SliderNextPrev';

class Slider {
	constructor(container) {
		this.INTERVAL = 6000;
		this.maxChanges = 10;
		this.currentChange = 0;
		this.container = container;
		this.currentId = 0;

		this.descriptionClass = '.js-phaeno-slider__texts';
		this.nextPrevClass = '.js-phaeno-slider__nextPrev';
	}

	init() {
		this.menuContainer = this.container.querySelector('.js-phaeno-slider__menu');
		this.menu = new SliderMenu(this.menuContainer);
		this.menu.init();
		this.menu.addListener(variables.events.SLIDER_MENU_CLICK, this.onMenuClick.bind(this));

		this.slidesContainer = this.container.querySelector('.js-phaeno-slider__slides');
		this.slides = new SliderSlides(this.slidesContainer);
		this.slides.init();

		this.slidesNo = this.slides.getNo();

		this.descriptionContainer = this.container.querySelector(this.descriptionClass);
		if(this.descriptionContainer) {
			this.descriptions = new SliderDescriptions(this.descriptionContainer);
			this.descriptions.init();
		}

		this.nextPrevContainer = this.container.querySelector(this.nextPrevClass);
		if(this.nextPrevContainer) {
			//pass main container, for hammer to work on the whole image
			this.nextPrevMenu = new SliderNextPrev(this.container);
			this.nextPrevMenu.init();
			this.nextPrevMenu.addListener(variables.events.NEXT_PREV_PREV, this.onNextPrevPrevClick.bind(this));
			this.nextPrevMenu.addListener(variables.events.NEXT_PREV_NEXT, this.onNextPrevNextClick.bind(this));
		}

		this.setActive(this.currentId);

		this.maxChanges = Math.max(this.maxChanges, this.slidesNo);
		if(this.container.dataset.phaenoAutoplay === 'true') this.startTimer();
	}

	onMenuClick(id) {
		this.setActive(id);
		this.stopTimer();
	}

	setActive(id) {
		this.currentId = id;
		this.slides.setActive(id);
		this.menu.setActive(id);

		if(this.descriptions) {
			const descriptionId = this.slides.getSlide(id).dataset.id;
			this.descriptions.setActive(descriptionId);
		}
	}

	startTimer() {
		this.intervalId = setInterval(this.showNext.bind(this), this.INTERVAL);
	}

	stopTimer() {
		clearInterval(this.intervalId);
	}

	showNext() {
		if (this.currentId < this.slidesNo - 1) {
			this.setActive(this.currentId + 1);
		} else {
			this.setActive(0);
		}

		if (++this.currentChange >= this.maxChanges) {
			this.stopTimer();
		}
	}

	showPrev() {
		if (this.currentId > 0) {
			this.setActive(this.currentId - 1);
		} else {
			this.setActive(this.slidesNo - 1);
		}
	}

	onNextPrevPrevClick() {
		this.showPrev();
		this.stopTimer();
	}

	onNextPrevNextClick() {
		this.showNext();
		this.stopTimer();
	}
}

export default Slider;
