import {EventEmitter} from 'events';

import delegate from 'delegate';
import Hammer from 'hammerjs';

import variables from '../../utils/variables';

class NextPrevMenu extends EventEmitter {
	constructor(container) {
		super();
		this.container = container;
		this.activeClass = 'active';
		this.currentId = null;

		this.buttonsClass = '.js-phaeno-nextPrevMenu__button';
		this.nextBtnClass = '.js-phaeno-nextPrevMenu__next';
		this.prevBtnClass = '.js-phaeno-nextPrevMenu__prev';
		this.contentClass = '.js-phaeno-nextPrevMenu__content';
		this.titleClass = '.js-phaeno-nextPrevMenu__title';

		this.menuId = this.container.dataset.phaenoMenuId;
	}

	init() {
		const buttons = this.container.querySelectorAll(this.buttonsClass);
		//HTMLCollection to array
		this.buttonsArr = [].slice.call(buttons);

		this.nextBtn = this.container.querySelectorAll(this.nextBtnClass);
		this.prevBtn = this.container.querySelectorAll(this.prevBtnClass);
		this.titleElement = this.container.querySelector(this.titleClass);

		const content = this.container.querySelectorAll(this.contentClass);
		//HTMLCollection to array
		this.contentArr = [].slice.call(content);

		this.contentLenght = this.contentArr.length;

		delegate(this.container, this.nextBtnClass, 'click', this.onNext.bind(this));
		delegate(this.container, this.prevBtnClass, 'click', this.onPrev.bind(this));

		delegate(this.container, this.buttonsClass, 'click', this.onBtn.bind(this));

		//hammer
		this.hammer = new Hammer(this.container);
		this.hammer.on('swipeleft dragleft', this.onSwipeLeft.bind(this));
		this.hammer.on('swiperight dragright', this.onSwipeRight.bind(this));
	}

	onBtn(e) {
		const id = e.delegateTarget.dataset.phaenoTrigger;
		this.setActive(id);
		this.emitChange();
	}

	onSwipeLeft() {
		this.onNext();
	}

	onSwipeRight() {
		this.onPrev();
	}

	onNext(e) {
		this.showNext();
		this.emitChange();
	}

	onPrev(e) {
		this.showPrev();
		this.emitChange();
	}

	showNext() {
		const btn = this.getButtonById(this.currentId);
		let no = this.buttonsArr.indexOf(btn);
		no = ++no % this.contentLenght;
		this.setActiveByNo(no);
	}

	showPrev() {
		const btn = this.getButtonById(this.currentId);
		let no = this.buttonsArr.indexOf(btn);
		no = (--no < 0) ? (this.contentLenght - 1) : no;
		this.setActiveByNo(no);
	}

	setActiveByNo(no) {
		const id = this.buttonsArr[no].dataset.phaenoTrigger;
		this.setActive(id);
	}

	setActive(id) {
		if (this.currentId === id) {
			return;
		} else if (this.currentId !== null) {
			this.setInactive(this.currentId);
		}

		const b = this.getButtonById(id);
		if (b) {
			b.classList.add(this.activeClass);
		}

		const c = this.getContentById(id);
		if (c) {
			c.classList.add(this.activeClass);
		}

		this.currentId = id;

		this.updateTitle();
	}

	setInactive(id) {
		const b = this.getButtonById(id);
		if (b) {
			b.classList.remove(this.activeClass);
		}

		const c = this.getContentById(id);
		if (c) {
			c.classList.remove(this.activeClass);
		}
	}

	getButtonById(id) {
		return this.container.querySelector(this.buttonsClass + '[data-phaeno-trigger="' + id + '"]');
	}

	getContentById(id) {
		return this.container.querySelector(this.contentClass + '[data-phaeno-target="' + id + '"]');
	}

	getCurrentId() {
		return this.currentId;
	}

	setCurrent(id) {
		this.setActive(id);
	}

	emitChange() {
		this.emit(variables.events.NEXT_PREV_MENU_CHANGE);
	}

	updateTitle() {
		const btn = this.getButtonById(this.currentId);
		this.titleElement.innerHTML = btn.dataset.phaenoTitle;
	}
}

export default NextPrevMenu;
