import {EventEmitter} from 'events';

import delegate from 'delegate';

import 'custom-event-polyfill';
import variables from '../../utils/variables';

class Accordion extends EventEmitter {
	constructor(container) {
		super();
		this.container = container;
		this.activeClass = 'active';
		this.currentId = null;
		this.buttonsClass = '.js-phaeno-accordion__button';
		this.bodyClass = '.js-phaeno-accordion__body';
		this.headClass = '.js-phaeno-accordion__head';
	}

	init() {
		this.buttonsSelector = this.buttonsClass;
		const buttons = this.container.querySelectorAll(this.buttonsSelector);
		this.buttonsArr = [].slice.call(buttons);

		this.bodySelector = this.bodyClass;
		const bodies = this.container.querySelectorAll(this.bodySelector);
		this.bodyArr = [].slice.call(bodies);

		this.headSelector = this.headClass;
		const heads = this.container.querySelectorAll(this.headSelector);
		this.headArr = [].slice.call(heads);

		delegate(this.container, this.buttonsSelector, 'click', this.onButtonClick.bind(this));
	}

	onButtonClick(e) {
		const id = e.delegateTarget.dataset.accordionTrigger;
		this.setActive(id);
	}

	setActive(id) {
		if (this.currentId === id) {
			this.currentId = null;
			this.setActiveButton(null);
			this.setActiveBody(null);
			this.setActiveHead(null);
			this.emitChange();
			return;
		}

		this.currentId = id;

		if (this.currentId !== null) {
			this.setActiveButton(this.currentId);
			this.setActiveBody(this.currentId);
			this.setActiveHead(this.currentId);
			this.emitChange();
		}
	}

	setActiveButton(id) {
		this.buttonsArr.forEach((el) => el.classList.remove(this.activeClass));

		if (this.getButtonById(id)) {
			this.getButtonById(id).classList.add(this.activeClass);
		}
	}

	setActiveBody(id) {
		this.bodyArr.forEach((el) => el.classList.remove(this.activeClass));

		if (this.getBodyById(id)) {
			this.getBodyById(id).classList.add(this.activeClass);
		}
	}

	setActiveHead(id) {
		this.headArr.forEach((el) => el.classList.remove(this.activeClass));

		if (this.getHeadById(id)) {
			this.getHeadById(id).classList.add(this.activeClass);
		}
	}

	getButtonById(id) {
		return this.container.querySelector(this.buttonsSelector + '[data-accordion-trigger="' + id + '"]');
	}

	getBodyById(id) {
		return this.container.querySelector(this.bodySelector + '[data-accordion-id="' + id + '"]');
	}

	getHeadById(id) {
		return this.container.querySelector(this.headSelector + '[data-accordion-id="' + id + '"]');
	}

	getCurrentId() {
		return this.currentId;
	}

	setCurrent(id) {
		this.setActive(id);
	}

	emitChange() {
		this.emit(variables.events.WORKSHOP_CHANGE);
		this.container.dispatchEvent(new CustomEvent(variables.events.POSSIBLE_HEIGHT_CHANGE, {bubbles: true}));
	}
}

export default Accordion;
