import delegate from 'delegate';

class Minigame {
	constructor(container) {
		this.VIDEO_URLS = ['../Resources/Public/Video/small.mp4', '../Resources/Public/Video/small2.mp4'];
		this.LOOP_TIMES = [{from: 1, to: 2.5}, {from: 0, to: 1.5}];
		this.container = container;
		this.videoClass = '.js-phaeno-minigame__video';
		this.videoElement = this.container.querySelector(this.videoClass);
		delegate(document.body, this.videoClass, 'click', this.onVideoClick.bind(this), false);

		this.isClicked = false;
		this.currentVideo = 0;
		this.videosNo = this.VIDEO_URLS.length;
		this.loadCurrentVideo();
	}

	init() {
		this.intervalId = setInterval(this.onEnterFrame.bind(this), 1000/20);
	}

	onEnterFrame() {
		if(this.videoElement.currentTime >= this.LOOP_TIMES[this.currentVideo].to && this.isClicked === false) {
			this.videoElement.currentTime = this.LOOP_TIMES[this.currentVideo].from;
            this.videoElement.play();
		} else if(this.videoElement.currentTime >= this.videoElement.duration) {
			this.playNextVideo();
			this.isClicked = false;
		}
	}

	playNextVideo() {
		this.currentVideo++;
		if(this.currentVideo >= this.videosNo) {
			this.currentVideo = 0;
		}
		this.loadCurrentVideo();
	}

	loadCurrentVideo() {
		this.videoElement.src = this.VIDEO_URLS[this.currentVideo];
	}

	onVideoClick(e) {
		if(this.isClicked === false) {
			this.isClicked = true;
		}
	}
}

export default Minigame;
