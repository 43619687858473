import {TweenMax} from 'gsap';

import getOffset from '../../utils/getOffset';
import getWindowSize from '../../utils/getWindowSize';
import getScrollPositionY from '../../utils/getScrollPositionY';

class ParallaxElement {
	constructor(container) {
		this.SPEEDS = {fast: 0.06, mid: 0.03, slow: 0.01, fast2: 0.12, mid2: 0.035, slow2: 0.015};
		this.RANDOM = 0.04;

		this.container = container;
		this.onResizeHandler = this.onResize.bind(this);
		window.addEventListener('resize', this.onResizeHandler);
		this.onScrollHandler = this.onScroll.bind(this);
		window.addEventListener('scroll', this.onScrollHandler);

		this.speed = Math.random() * 0.15;
		this.offset = 0;

		const datasetSpeed = this.container.dataset.parallaxspeed;
		if (datasetSpeed) {
			this.speed = isNaN(datasetSpeed) ?
				this.SPEEDS[datasetSpeed] * 0.5 :
				Number(this.container.dataset.parallaxspeed);
		}

		const datasetRand = this.container.dataset.parallaxrand;
		if (datasetRand && datasetRand === 'true') {
			this.speed += (Math.random() - 0.5) * this.RANDOM;
		}

		this.onResize();
		setTimeout(this.onResize.bind(this), 100);
		this.onScroll();
	}

	onResize() {
		this.elementPos = getOffset(this.container).top;
		this.windowHeight = getWindowSize().height;

		const s = getWindowSize();
		const w = s.width;
		const h = s.height;

		//small hack - iphone triggers unexpected on resize events
		//do this only when window size has really changed
		if (this.windowW === w && this.windowH === h) {
			return;
		} else {
			this.windowW = w;
			this.windowH = h;

			this.topPos = getOffset(this.container).top;
		}

		this.startPos = Math.max(this.topPos - this.windowH * 0.25, 0);

		this.onScroll(true);
	}

	onScroll(isNoAnimations) {
		const scrollTop = getScrollPositionY();

		const diff = Math.max(Math.min(scrollTop - this.startPos, this.windowH), -this.windowH);

		const topP = (diff) * this.speed + this.offset;

		let duration = 0.5;
		if (isNoAnimations === true) {
			duration = 0;
		}

		TweenMax.killTweensOf(this.container);
		TweenMax.to(this.container, duration, {y: topP, ease: 'Power1.easeOut', force3D: true});
	}

	deinit() {
		window.removeEventListener('resize', this.onResizeHandler);
		window.removeEventListener('scroll', this.onScrollHandler);
	}
}

export default ParallaxElement;
