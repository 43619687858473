const variables = {};

variables.events = {};
variables.events.SLIDER_MENU_CLICK = 'slider-menu-click';
variables.events.NEXT_PREV_MENU_CHANGE = 'next-prev-menu-click';
variables.events.TAB_MENU_CHANGE = 'tab-menu-click';
variables.events.POSSIBLE_HEIGHT_CHANGE = 'possible-height-change';
variables.events.DICTIONARY_READY = 'dictionary-ready';
variables.events.DAY_CLOSED = 'day-closed';
variables.events.CALENDAR_UPDATE = 'calendar-update';
variables.events.CALENDAR_TAG_CLICK = 'calendar-tag-click';
variables.events.WORKSHOP_CHANGE = 'workshop-click';
variables.events.ACCORDION_CHANGE = 'workshop-click';
variables.events.WINDOW_HEIGHT_CHANGED = 'window-height-changed';
variables.events.NEXT_PREV_PREV = 'next-prev-prev';
variables.events.NEXT_PREV_NEXT = 'next-prev-next';

variables.animDuration16 = 0.0625;
variables.animDuration8 = 0.125;
variables.animDuration4 = 0.25;
variables.animDuration2 = 0.5;
variables.animDuration = 1;
variables.animDurationLong = 2;

variables.animEase = 'Power0';
variables.animEaseOut = 'Power4.easeOut';
variables.animEaseInOut = 'Power4.easeInOut';

variables.calendar = {};
variables.calendar.from = 9;
variables.calendar.to = 19;

export default variables;
