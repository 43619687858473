import PageScrolled from './PageScrolled';
import Hamburger from './Hamburger';
import Anchor from './Anchor';
import Bookmarks from './Bookmarks';

class Navigation {
	init(heightChecker) {
		this.heightChecker = heightChecker;

		this.pageScrolled = new PageScrolled();
		this.pageScrolled.init();

		this.hamburger = new Hamburger();
		this.hamburger.init();

		this.anchor = new Anchor();
		this.anchor.init();

		this.bookmarks = new Bookmarks(this.heightChecker);
		this.bookmarks.init();
	}
}

export default Navigation;
