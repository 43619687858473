import HeightChecker from './navigation/HeightChecker';
import Navigation from './navigation/Navigation';
import Calendar from './calendar/Calendar';
import Slider from './slider/Slider';
import SlidingHighlights from './slidinghighlights/SlidingHighlights';
import TabMenu from './tabmenu/TabMenu';
import InfoTabMenu from './tabmenu/InfoTabMenu';
import Workshops from './workshops/Workshops';
import Accordion from './accordion/Accordion';
import Experimente from './experimente/Experimente';
import NextPrevTabMenu from './nextprevmenu/NextPrevTabMenu';
import Video from './video/Video';
import Animations from './animations/Animations';
import Minigame from './minigame/Minigame';
import Luma from './luma/Luma';

class PhaenoPage {
	init() {
		//height checker
		this.heightChecker = new HeightChecker();

		// nav
		this.navigation = new Navigation();
		this.navigation.init(this.heightChecker);

		// calendar - only one on a page
		const calendarContainer = document.querySelectorAll('.js-phaeno-calendar');
		for (let i = 0; i < calendarContainer.length; i++) {
			const calendar = new Calendar(calendarContainer[i]);
			calendar.init();
		}

		// sliders
		const sliderContainers = document.querySelectorAll('.js-phaeno-slider');
		this.sliders = [];
		for (let i = 0; i < sliderContainers.length; i++) {
			const slider = new Slider(sliderContainers[i]);
			slider.init();
			this.sliders.push(slider);
		}

		// sliding highlights
		const slidingHihglightsContainers = document.querySelectorAll('.js-phaeno-slidingHighlights');
		this.slidingHighlights = [];
		for (let i = 0; i < slidingHihglightsContainers.length; i++) {
			const slidingHighlight = new SlidingHighlights(slidingHihglightsContainers[i]);
			slidingHighlight.init();
			this.slidingHighlights.push(slidingHighlight);
		}

		// videos
		const videosContainers = document.querySelectorAll('.js-phaeno-video');
		this.videos = [];
		for (let i = 0; i < videosContainers.length; i++) {
			const video = new Video(videosContainers[i]);
			video.init();
			this.videos.push(video);
		}

		// infotabmenu - main homepage info element
		const infoTabMenuContainer = document.querySelector('.js-phaeno-infoTabMenu');
		if (infoTabMenuContainer) {
			const infoTabMenu = new InfoTabMenu(infoTabMenuContainer);
			infoTabMenu.init();
		}

		// tabmenu
		const tabmenuContainers = document.querySelectorAll('.js-phaeno-tabMenu');
		this.tabmenus = [];
		for (let i = 0; i < tabmenuContainers.length; i++) {
			const tabmenu = new TabMenu(tabmenuContainers[i]);
			tabmenu.init();
			this.tabmenus.push(tabmenu);
		}

		// nextprevtabmenu
		const nextprevtabmenuContainers = document.querySelectorAll('.js-phaeno-nextPrevTabMenu');
		this.nextprevtabmenus = [];
		for (let i = 0; i < nextprevtabmenuContainers.length; i++) {
			const nextPrevTabMenu = new NextPrevTabMenu(nextprevtabmenuContainers[i]);
			nextPrevTabMenu.init();
			this.nextprevtabmenus.push(nextPrevTabMenu);
		}

		// workshops
		document.querySelectorAll('.js-phaeno-workshops').forEach((wsElem) => {
			new Workshops(wsElem).init();
		});

		// accordions
		/*const accordionContainers = document.querySelectorAll('.js-phaeno-accordion');
		this.accordions = [];
		for (let i = 0; i < accordionContainers.length; i++) {
			const accordion = new Accordion(accordionContainers[i]);
			accordion.init();
			this.accordions.push(accordion);
		}*/

		//IMPORTANT:
		//this one works a little bit different
		//if there's at least one accordion element on the page
		//it creases an accordion instance
		//but document is passed as container.
		const accordionContainers = document.querySelector('.js-phaeno-accordion__body');
		if (accordionContainers) {
			const accordion = new Accordion(document);
			accordion.init();
		}



		// experimente
		const experimenteContainer = document.querySelector('.js-phaeno-experimente');
		if (experimenteContainer) {
			const experimente = new Experimente(experimenteContainer);
			experimente.init();
		}

		// show on scroll elements
		this.animations = new Animations(document.body);
		this.animations.init();

		// minigame
		const minigameContainer = document.querySelector('.js-phaeno-minigame');
		if (minigameContainer) {
			const minigame = new Minigame(minigameContainer);
			minigame.init();
		}

		// luma
		const lumaContainers = document.querySelectorAll('.js-phaeno-luma');
		this.lumas = [];
		for (let i = 0; i < lumaContainers.length; i++) {
			const luma = new Luma(lumaContainers[i]);
			luma.init();
			this.lumas.push(luma);
		}
	}
}

export default PhaenoPage;
