import {EventEmitter} from 'events';

import delegate from 'delegate';
import Hammer from 'hammerjs';

import variables from '../../utils/variables';

class SliderNextPrev extends EventEmitter {
	constructor(container) {
		super();
		this.container = container;

		this.nextBtnClass = '.js-phaeno-slider__nextPrev__next';
		this.prevBtnClass = '.js-phaeno-slider__nextPrev__prev';
	}

	init() {
		this.nextBtn = this.container.querySelectorAll(this.nextBtnClass);
		this.prevBtn = this.container.querySelectorAll(this.prevBtnClass);

		delegate(this.container, this.nextBtnClass, 'click', this.onNext.bind(this));
		delegate(this.container, this.prevBtnClass, 'click', this.onPrev.bind(this));

		//hammer
		this.hammer = new Hammer(this.container);
		this.hammer.on('swipeleft dragleft', this.onSwipeLeft.bind(this));
		this.hammer.on('swiperight dragright', this.onSwipeRight.bind(this));
	}

	onSwipeLeft() {
		this.onNext();
	}

	onSwipeRight() {
		this.onPrev();
	}

	onNext(e) {
		this.emitNext();
	}

	onPrev(e) {
		this.emitPrev();
	}

	emitNext() {
		this.emit(variables.events.NEXT_PREV_NEXT);
	}
	emitPrev() {
		this.emit(variables.events.NEXT_PREV_PREV);
	}
}

export default SliderNextPrev;
