import {EventEmitter} from 'events';

import delegate from 'delegate';

import 'custom-event-polyfill';
import variables from '../../utils/variables';

class TabMenu extends EventEmitter {
	//
	// IMPORTANT: if module contains another 'next prev' or 'tab' module
	// all module's element (js-phaeno-tabMenu, js-phaeno-tabMenu__button, js-phaeno-tabMenu__content)
	// need to have a 'data-phaeno-menu-id' data added with the same String.
	// If not, it can be skipped.
	//

	constructor(container) {
		super();
		this.container = container;
		this.activeClass = 'active';
		this.currentId = null;
		this.isAlwaysOpened = false;
		this.isAlwaysOpenedData = 'data-tabmenu-always-open';
		this.isOnRollOver = false;
		this.isOnRollOverData = 'data-tabmenu-rollover';

		this.buttonsClass = '.js-phaeno-tabMenu__button';
		this.closeButtonsClass = '.js-phaeno-tabMenu__close-button';
		this.contentClass = '.js-phaeno-tabMenu__content';

		this.isWithId = false;

		this.delay = 250;
		this.wait = null;
	}

	init() {
		this.menuId = this.container.dataset.phaenoMenuId;
		if (this.menuId) {
			this.isWithId = true;
		}

		this.buttonsSelector = this.buttonsClass;
		if (this.isWithId) {
			this.buttonsSelector += '[data-phaeno-menu-id="' + this.menuId + '"]';
		}
		const buttons = this.container.querySelectorAll(this.buttonsSelector);

		// HTMLCollection to array
		this.buttonsArr = [].slice.call(buttons);

		this.contentSelector = this.contentClass;
		if (this.isWithId) {
			this.contentSelector += '[data-phaeno-menu-id="' + this.menuId + '"]';
		}
		const content = this.container.querySelectorAll(this.contentSelector);

		// HTMLCollection to array
		this.contentArr = [].slice.call(content);

		// check if alwaysOpen
		if (this.container.hasAttribute(this.isAlwaysOpenedData)) {
			this.setIsAlwaysOpen(true);
		}

		// check if changes on rollover
		if (this.container.hasAttribute(this.isOnRollOverData)) {
			this.isOnRollOver = true;
		}

		// toggle/activation events
		if (this.isOnRollOver) {
			delegate(this.container, this.buttonsSelector, 'mouseover', this.onTab.bind(this));
		} else {
			delegate(this.container, this.buttonsSelector, 'click', this.onTab.bind(this));
		}

		// close events
		delegate(this.container, this.closeButtonsClass, 'click', this.deactivateCurrent.bind(this));
	}

	setIsAlwaysOpen(isOpen) {
		this.isAlwaysOpened = isOpen;

		if (this.isAlwaysOpened) {
			const id = this.buttonsArr[0].getAttribute('data-phaeno-trigger');
			this.setActive(id);
		}
	}

	onTab(e) {
		const id = e.delegateTarget.dataset.phaenoTrigger;
		this.setActive(id, true);
	}

	setActive(id, delayed = false) {
		if (this.currentId === id) {
			if (!this.isAlwaysOpened) {
				this.deactivateCurrent();
			}

			return;
		}

		this.currentId = id;

		if (this.currentId !== null) {
			this.setActiveButton(this.currentId);
		}

		if (!delayed) {
			this.setActiveContent(this.currentId);
			this.emitChange();
		} else {
			clearTimeout(this.wait);

			this.wait = window.setTimeout((that, newId) => {
				that.setActiveContent(newId);
				that.emitChange();
			}, this.delay, this, id);
		}
	}

	deactivateCurrent() {
		this.currentId = null;
		this.setActiveButton(null);
		this.setActiveContent(null);
		this.emitChange();
	}

	setActiveButton(id) {
		this.buttonsArr.forEach((el) => el.classList.remove(this.activeClass));

		if (this.getButtonById(id)) {
			this.getButtonById(id).classList.add(this.activeClass);
		}
	}

	setActiveContent(id) {
		this.contentArr.forEach((el) => el.classList.remove(this.activeClass));

		if (this.getContentById(id)) {
			this.getContentById(id).classList.add(this.activeClass);
		}
	}

	getButtonById(id) {
		return this.container.querySelector(this.buttonsSelector + '[data-phaeno-trigger="' + id + '"]');
	}

	getContentById(id) {
		return this.container.querySelector(this.contentSelector + '[data-phaeno-target="' + id + '"]');
	}

	getCurrentId() {
		return this.currentId;
	}

	setCurrent(id) {
		this.setActive(id);
	}

	emitChange() {
		this.emit(variables.events.TAB_MENU_CHANGE);
		this.container.dispatchEvent(new CustomEvent(variables.events.POSSIBLE_HEIGHT_CHANGE, {bubbles: true}));
	}
}

export default TabMenu;
