import {EventEmitter} from 'events';

import variables from '../../utils/variables';

import CalendarDictionary from './CalendarDictionary';

/* eslint-disable quote-props */
const EVENT_ID_TYPE_MAP = {
	'4': 'opening',
	'14': 'label',
	'158': 'label',
};

class CalendarJsonRebuilder extends EventEmitter {
	init(url) {
		this.calendarDictionary = new CalendarDictionary();
		this.calendarDictionary.init(url);
		this.calendarDictionary.on(variables.events.DICTIONARY_READY, this.onDictionaryReady.bind(this));
	}

	onDictionaryReady() {
		this.emit(variables.events.DICTIONARY_READY);
	}

	/**
	 * rebuilds events json from a long array of events
	 * into nested set of arrays grouped by months[days[event[hours]]]
	 *
	 * @param {object} props props
	 * @param {*[]} props.eventDates event dates
	 * @param {*[]} props.events events
	 * @param {Date} currentMonth
	 * @returns {{months: Array}|*} result
	 */
	rebuild({eventDates, events}, currentMonth) {
		const today = new Date();

		const months = [];
		eventDates.forEach(eventDate => {
			const date = new Date(eventDate.startDate);

			if (date.getTime() < currentMonth.getTime()) {
				date.setUTCFullYear(currentMonth.getUTCFullYear());
				date.setUTCMonth(currentMonth.getUTCMonth());
			}

			const month = date.getFullYear() * 1000 + date.getMonth(); // put year in index to preserve correct order
			const m = date.getMonth();
			if (!months[month]) {
				// new month
				months[month] = {
					name: this.calendarDictionary.getMonth(m),
					days: [],
				};
			}

			const day = date.getDate();
			if (!months[month].days[day]) {
				const weekDay = date.getDay();
				const isToday = today.toDateString() === date.toDateString();
				const isHoliday = weekDay === 0 || weekDay === 6 || this.calendarDictionary.checkHoliday(date);

				// new day
				months[month].days[day] = {
					dateString: `${date.getFullYear()}_${date.getMonth() + 1}_${date.getDate()}`,
					date: day,
					events: [],
					day: this.calendarDictionary.getDayShort(weekDay),
					dayFull: this.calendarDictionary.getDay(weekDay),
					isHoliday: isHoliday,
					isActive: isToday,
				};
			}

			const hours = {
				start: date.toISOString(),
				end: eventDate.endDate,
			};
			const event = events[eventDate.eventIdentifier];
			// event already exists - just add hours
			const i = months[month].days[day].events.findIndex(({id}) => id === event.identifier);
			if (i > -1) {
				months[month].days[day].events[i].hours.push(hours);
				return;
			}

			const eventResult = {
				name: event.name,
				id: event.identifier,
				eventType: EVENT_ID_TYPE_MAP[event.identifier] || 'event',
				hours: [hours],
			};

			switch (eventResult.eventType) {
				case 'opening':
				case 'label':
					months[month].days[day].events.unshift(eventResult);
					break;
				default:
					months[month].days[day].events.push(eventResult);
			}
		});

		// re-index arrays https://stackoverflow.com/a/29962957
		return months.filter(month => {
			month.days = month.days.filter(_ => _);
			return month;
		});
	}
}

export default CalendarJsonRebuilder;
