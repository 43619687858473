class Hamburger {
	constructor() {
		this.container = document.querySelector('.js-phaeno-mainNav');
		this.button = document.querySelector('.js-phaeno-hamburger');
		this.openString = 'open';
	}

	init() {
		this.button.addEventListener('click', this.onHamburgerClick.bind(this));
	}

	onHamburgerClick(e) {
		this.container.classList.toggle(this.openString);
	}
}

export default Hamburger;
