import {TweenMax} from 'gsap';

import getOffset from './getOffset';
import variables from './variables';
import getScrollPositionY from './getScrollPositionY';

const html = document.documentElement;
const body = document.body;
let tween;
window.addEventListener('wheel', onWheel);

let navHeight = document.querySelector('.phaeno-header');
navHeight = navHeight && navHeight.getBoundingClientRect();
navHeight = navHeight && navHeight.bottom;
const NAV_HEIGHT = navHeight || 0;

export default function scrollTo(elem) {
	const pos = typeof elem === 'number' ? elem : getOffset(elem).top;
	const obj = {currentPos: getScrollPositionY()};
	tween = TweenMax.to(obj, variables.animDuration, {
		currentPos: pos,
		ease: variables.animEaseInOut,
		onUpdate: applyValue,
		onUpdateParams: [obj],
	});
}

export function scrollToFixNav(elem) {
	let pos = (typeof elem === 'number' ? elem : getOffset(elem).top) - NAV_HEIGHT;
	pos = Math.max(pos, 0);
	scrollTo(pos);
}

function applyValue(obj) {
	const p = obj.currentPos;
	html.scrollTop = p;
	body.scrollTop = p;
}

function onWheel() {
	if (tween) {
		tween.kill();
	}
}
