class SliderDescriptions {
	constructor(container) {
		this.container = container;
		this.currentId = null;
		this.textClass = '.js-phaeno-slider__text';
		this.activeClass = 'active';
	}

	init() {
		this.texts = this.container.querySelectorAll(this.textClass);
	}

	setActive(id) {
		if (this.currentId !== id) {
			let el = this.container.querySelector(this.textClass + '[data-id="' + this.currentId + '"]');
			if(el) el.classList.remove(this.activeClass);
			el = this.container.querySelector(this.textClass + '[data-id="' + id + '"]');
			if(el) el.classList.add(this.activeClass);
			this.currentId = id;
		}
	}
}

export default SliderDescriptions;
