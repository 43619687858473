import {TweenMax} from 'gsap';
import delegate from 'delegate';

import variables from '../../utils/variables';

import templateDescription from './templates/calendareventdescription';

class CalendarEventDescription {
	constructor() {
		this.eventClass = '.js-phaeno-calendar__event';
		this.buttonClass = '.js-phaeno-calendar__button';
		this.descriptionCloseClass = '.js-phaeno-calendarEvent__close';
		this.descriptionClass = '.js-phaeno-calendarEvent';
		this.contentClass = '.js-phaeno-calendarEvent__content';
	}

	init(data) {
		this.data = data;
		delegate(document.body, this.buttonClass, 'click', this.onClick.bind(this), false);
		delegate(document.body, this.descriptionCloseClass, 'click', this.onCloseClick.bind(this), false);

		this.onResizeHandler = this.onResize.bind(this);
		// no need to remove the listener... mem-leaks ftw
		window.addEventListener('resize', this.onResizeHandler);
	}

	setData(data) {
		this.data = data;
	}

	/**
	 * @param {MouseEvent} e click event
	 */
	onClick(e) {
		e.stopImmediatePropagation();
		const target = e.delegateTarget;
		const container = target.closest(this.eventClass);
		const id = container.dataset.eventid;
		const data = this.data[id];

		if (data) {
			this.hideDescriptions();

			if (target === this.currentTarget) {
				this.currentTarget = null;
				return;
			}

			this.currentTarget = target;

			container.insertAdjacentHTML('afterend', templateDescription(this.data[id]));
			const description = container.nextElementSibling;
			const content = description.querySelector(this.contentClass);
			const contentHeight = content.clientHeight;

			description.querySelectorAll('img').forEach(
				/**
				 * @param {HTMLImageElement} img image element
				 */
				(img) => {
					if (!img.complete) {
						img.onload = this.onResizeHandler;
					}
				}
			);

			TweenMax.to(description, 0, {
				alpha: 0,
				height: 0,
				ease: variables.animEaseInOut,
				force3D: true,
			});
			TweenMax.to(description, variables.animDuration2, {
				alpha: 1,
				height: contentHeight,
				ease: variables.animEaseInOut,
				force3D: true,
			});
		}
	}

	onCloseClick(e) {
		e.stopImmediatePropagation();
		this.hideDescriptions();
	}

	hideAll() {
		this.hideDescriptions();
		this.currentTarget = null;
	}

	hideDescriptions() {
		const descriptions = document.body.querySelectorAll(this.descriptionClass);

		for (let i = 0; i < descriptions.length; i++) {
			const el = descriptions[i];

			TweenMax.to(el, variables.animDuration2, {
				alpha: 0,
				height: 0,
				ease: variables.animEaseInOut,
				force3D: true,
				onComplete: CalendarEventDescription.removeDescription.bind(this, el),
			});
		}
	}

	static removeDescription(el) {
		el.parentNode.removeChild(el);
	}

	onResize(e) {
		this.setCurrentHeight();
	}

	setCurrentHeight() {
		const descriptions = document.body.querySelectorAll(this.descriptionClass);

		for (let i = 0; i < descriptions.length; i++) {
			const el = descriptions[i];
			const content = el.querySelector(this.contentClass);

			this.setHeight(el, content.clientHeight);
		}
	}

	setHeight(el, h) {
		this.yo = '';

		TweenMax.killTweensOf(el);
		TweenMax.to(el, variables.animDuration2, {
			alpha: 1,
			height: h,
			ease: variables.animEaseOut,
			force3D: true,
		});
	}
}

export default CalendarEventDescription;
