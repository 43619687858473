class SliderSlides {
	constructor(container) {
		this.container = container;
		this.currentId = null;
		this.slidesClass = '.js-phaeno-slider__slide';
		this.activeClass = 'active';
	}

	init() {
		this.slides = this.container.querySelectorAll(this.slidesClass);
	}

	setActive(id) {
		if (this.currentId !== null) {
			this.slides[this.currentId].classList.remove(this.activeClass);
		}

		this.slides[id].classList.add(this.activeClass);
		this.currentId = id;
	}

	getSlide(id) {
		return this.slides[id];
	}

	getNo() {
		return this.slides.length;
	}
}

export default SliderSlides;
