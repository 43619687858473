import {EventEmitter} from 'events';

import httpRequest from 'superagent';

import variables from '../../utils/variables';

class CalendarDictionary extends EventEmitter {
	init(url) {
		this.loadDictionary(url);
	}

	loadDictionary(url) {
		httpRequest
			.get(url)
			.end((error, response) => {
					if (error || !response.ok) {
						console.error(error);
					} else {
						this.onDictionaryLoaded(response.text);
					}
				}
			);
	}

	onDictionaryLoaded(response) {
		this.dictionary = JSON.parse(response);
		this.dispatchReady();
	}

	dispatchReady() {
		this.emit(variables.events.DICTIONARY_READY);
	}

	getMonth(id) {
		return this.dictionary.months[id];
	}

	getDay(id) {
		return this.dictionary.days[id];
	}

	getDayShort(id) {
		return this.dictionary.daysshort[id];
	}

	getWord(word) {
		return this.dictionary[word];
	}

	checkHoliday(date) {
		const holidays = this.dictionary.holidays;
		let isHoliday = false;

		for (let i = 0; i < holidays.length; i++) {
			const d = new Date(holidays[i]);

			if (date.toDateString() === d.toDateString()) {
				isHoliday = true;
				break;
			}
		}

		return isHoliday;
	}
}

export default CalendarDictionary;
