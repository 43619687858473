import {EventEmitter} from 'events';

import delegate from 'delegate';

import variables from '../../utils/variables';

class CalendarUpdateLinks extends EventEmitter {
	constructor() {
		super();
		this.SELECTOR = '[data-updatecalendar]';
	}

	init() {
		delegate(document.body, this.SELECTOR, 'click', this.onClick.bind(this));

		this.on(variables.events.CALENDAR_UPDATE, (url) => {
			document.queryAll(this.SELECTOR).forEach(link => {
				if (link.getAttribute('data-url') === url) {
					link.classList.add('active');
				} else {
					link.classList.remove('active');
				}
			});
		});
	}

	onClick(e) {
		const url = e.delegateTarget.getAttribute('data-url');
		this.emit(variables.events.CALENDAR_UPDATE, url);
	}
}

export default CalendarUpdateLinks;
