import {EventEmitter} from 'events';

import getWindowSize from '../../utils/getWindowSize';
import variables from '../../utils/variables';

class HeightChecker extends EventEmitter {
	constructor() {
		super();
		this.INTERVAL = 2;
		this.currentHeight = 0;
		this.intervalId = setInterval(this.checkHeight.bind(this), this.INTERVAL * 1000);
	}

	checkHeight() {
		const height = getWindowSize().height;
		if(this.currentHeight !== height) {
			this.currentHeight = height;
			this.emit(variables.events.WINDOW_HEIGHT_CHANGED);
		}
	}
}

export default HeightChecker;
