import {TweenMax} from 'gsap';
import Hammer from 'hammerjs';

import {range} from '../../utils/math-x';
import variables from '../../utils/variables';

class SlidingHighlight {
	constructor(container) {
		this.container = container;

		this.SPEED = 11;
		this.SLIDE_WIDHT_MAX = 600;
		this.SLIDE_WIDHT_MIN = 300;

		this.slidesClass = '.js-phaeno-slidingHighlights__slides';
		this.slidesContainerClass = '.js-phaeno-slidingHighlights__slidesContainer';
		this.prevButtonClass = '.js-phaeno-slidingHighlights__prev';
		this.nextButtonClass = '.js-phaeno-slidingHighlights__next';

		this.slidesElement = this.container.querySelector(this.slidesClass);

		this.slidesContainer = this.container.querySelector(this.slidesContainerClass);
		this.currentSliderPosition = 0;
		this.slideElementWidth = 505;

		this.prevButton = this.container.querySelector(this.prevButtonClass);
		this.nextButton = this.container.querySelector(this.nextButtonClass);

		this.slides = this.slidesContainer.querySelectorAll('.js-phaeno-slidingHighlights__slide');
		this.firstSlide = this.slides[0];
		this.slidesNo = this.slides.length;
		this.getBordersValues();
	}


	init() {
		this.hammer = new Hammer(this.slidesContainer);
		this.hammer.on('panleft panright', this.onPan.bind(this));
		this.hammer.on('panend', this.onPanEnd.bind(this));

		this.onResizeHandler = this.onResize.bind(this);
		window.addEventListener('resize', this.onResizeHandler);
		window.addEventListener('load', this.onResizeHandler);
		this.onResize();

		this.prevButton.addEventListener('click', this.onPrevButton.bind(this));
		this.nextButton.addEventListener('click', this.onNextButton.bind(this));

		//this.prevButton.addEventListener('mouseover', this.onPrevButtonOver.bind(this));
		//this.prevButton.addEventListener('mouseout', this.onPrevButtonOut.bind(this));
		//this.nextButton.addEventListener('mouseover', this.onNextButtonOver.bind(this));
		//this.nextButton.addEventListener('mouseout', this.onNextButtonOut.bind(this));

		this.startInterval();
	}

	onPan(e) {
		this.setSliderPosition(e.deltaX);
	}

	onPanEnd(e) {
		this.updateCurrentPosition();
	}

	updateCurrentPosition() {
		this.currentSliderPosition = parseInt(this.slidesContainer.style.left, 10);
	}

	setSliderPosition(pos) {
		let nextVal = this.currentSliderPosition + pos;
		if (nextVal > this.maxVal) nextVal = this.minVal;
		if (nextVal < this.minVal) nextVal = this.maxVal;
		nextVal = range(nextVal, this.minVal, this.maxVal + this.slideElementWidth);
		nextVal = Math.round(nextVal/this.slideElementWidth) * this.slideElementWidth;
		this.slidesContainer.style.left = nextVal + 'px';
	}

	setSliderPositionAnim(pos) {
		let nextVal = this.currentSliderPosition + pos;
		if (nextVal > this.maxVal) nextVal = this.minVal;
		if (nextVal < this.minVal) nextVal = this.maxVal;
		nextVal = range(nextVal, this.minVal, this.maxVal + this.slideElementWidth);
		nextVal = Math.round(nextVal/this.slideElementWidth) * this.slideElementWidth;
		TweenMax.to(this.slidesContainer, variables.animDuration2, {
			left: nextVal,
			ease: variables.animEaseInOut,
			onComplete: this.updateCurrentPosition.bind(this),
		});
	}

	getBordersValues() {
		this.maxVal = 0;
		this.minVal = -(this.firstSlide.offsetWidth * this.slidesNo) + this.slidesElement.offsetWidth;
		this.setSliderPosition(0);
	}

	onResize() {
		//set slider height
		this.slidesElement.style.height = this.slidesContainer.offsetHeight + 'px';

		//set slides width
		this.slideElementWidth = range(this.slidesElement.offsetWidth * 0.5, this.SLIDE_WIDHT_MIN, this.SLIDE_WIDHT_MAX);
		for(let i=0; i<this.slidesNo; i++) {
			this.slides[i].style.width = this.slideElementWidth + 'px';
		}

		//set slider width
		this.slidesContainer.style.width = this.slideElementWidth * this.slidesNo + 200 + 'px';

		this.getBordersValues();
	}

	onPrevButton() {
		this.setSliderPositionAnim(this.slideElementWidth);
	}

	onNextButton() {
		this.setSliderPositionAnim(-this.slideElementWidth);
	}

	onPrevButtonOver() {
		this.currentSpeed = this.SPEED;
	}

	onPrevButtonOut() {
		this.currentSpeed = 0;
	}

	onNextButtonOver() {
		this.currentSpeed = -this.SPEED;
	}

	onNextButtonOut() {
		this.currentSpeed = 0;
	}

	startInterval() {
		this.intervalId = setInterval(this.onInterval.bind(this), 1000/60);
	}

	onInterval() {
		if(this.currentSpeed) {
			this.setSliderPosition(this.currentSpeed);
			this.updateCurrentPosition();
		}
	}
}

export default SlidingHighlight;
