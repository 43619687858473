import {EventEmitter} from 'events';

import delegate from 'delegate';

import variables from '../../utils/variables';

class SliderMenu extends EventEmitter {
	constructor(container) {
		super();
		this.container = container;
		this.activeClass = 'active';
		this.currentId = null;
	}

	init() {
		const buttonsClass = '.js-phaeno-slider__menuButton';
		const buttons = this.container.querySelectorAll(buttonsClass);
		// HTMLCollection to array
		this.buttonsArr = [].slice.call(buttons);
		delegate(this.container, buttonsClass, 'click', this.onButtonClick.bind(this));
	}

	onButtonClick(e) {
		const id = this.buttonsArr.indexOf(e.delegateTarget);
		this.emit(variables.events.SLIDER_MENU_CLICK, id);
	}

	setActive(id) {
		if (this.currentId !== null) {
			this.buttonsArr[this.currentId].classList.remove(this.activeClass);
		}

		this.buttonsArr[id].classList.add(this.activeClass);
		this.currentId = id;
	}
}

export default SliderMenu;
