import getOffset from '../../utils/getOffset';
import getWindowSize from '../../utils/getWindowSize';
import getScrollPositionY from '../../utils/getScrollPositionY';

class ShowOnScrollElement {
	constructor(container) {
		//percentage of screen height where to start animation
		this.START_MARGIN = 0.11;
		this.CLASS_NAME = 'show';

		this.container = container;
	}

	init() {
		this.onResizeHandler = this.onResize.bind(this);
		window.addEventListener('resize', this.onResizeHandler);
		this.onScrollHandler = this.onScroll.bind(this);
		window.addEventListener('scroll', this.onScrollHandler);

		this.onResize();
		setTimeout(this.onResize.bind(this), 100);
		this.onScroll();
	}

	onResize() {
		this.elementPos = getOffset(this.container).top;
		this.windowHeight = getWindowSize().height;
	}

	onScroll() {
		this.scrollPos = getScrollPositionY();
		this.check();
	}

	check() {
		if (this.scrollPos + this.windowHeight * (1 - this.START_MARGIN ) > this.elementPos) {
			this.container.classList.add(this.CLASS_NAME);
			//class is added only ones for each module.
			//so we can deinit here
			this.deinit();
		}
	}

	deinit() {
		window.removeEventListener('resize', this.onResizeHandler);
		window.removeEventListener('scroll', this.onScrollHandler);
	}
}

export default ShowOnScrollElement;
