import getScrollPositionY from '../../utils/getScrollPositionY';
import getOffset from '../../utils/getOffset';
import {range} from '../../utils/math-x';
import variables from '../../utils/variables';

class Bookmark {
	constructor(element, nextElement, heightChecker) {
		this.TOP_MARGIN = 200;

		this.heightChecker = heightChecker;

		this.element = element;
		this.nextElement = nextElement;
		//console.log(this.element, this.nextElement);
		if(this.nextElement) this.nextContainer = this.nextElement.closest('.js-phaeno-bookmarkContainer');

		this.container = this.element.closest('.js-phaeno-bookmarkContainer');
		this.label = this.element.querySelector('.js-phaeno-bookmarkText');

		this.heightChecker.on(variables.events.WINDOW_HEIGHT_CHANGED, this.onWindowHEightChanged.bind(this));
	}

	init() {
		this.onScrollHandler = this.onScroll.bind(this);
		window.addEventListener('scroll', this.onScrollHandler);
		this.onResizeHandler = this.onResize.bind(this);
		window.addEventListener('resize', this.onResizeHandler);

		this.onResize();
		this.onScroll();
	}

	onWindowHEightChanged() {
		this.onResize();
	}

	onScroll(e) {
		this.scrollPos = getScrollPositionY();
		const pos = this.scrollPos - this.containerPos;
		this.element.style.top = range(pos, this.minPosition, this.maxPosition) + 'px';
	}

	onResize(e) {
		const labelWidth = this.label.clientWidth;
		let height = this.container.clientHeight;

		//if there's next bookmark after this one and there's some content
		// without bookmark in between - let this one go next to this content
		if(this.nextElement) {
			height = getOffset(this.nextContainer).top - getOffset(this.container).top;
		}

		this.minPosition = 0;
		this.maxPosition = height - labelWidth - 100;

		this.containerPos = getOffset(this.container).top;

		this.onScroll();
	}
}

export default Bookmark;
