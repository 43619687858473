import delegate from 'delegate';

import getOffset from '../../utils/getOffset';
import scrollTo from '../../utils/scrollTo';

class Anchor {
	constructor() {
		this.SELECTOR = '[data-anchor]';
	}

	init() {
		delegate(document.body, this.SELECTOR, 'click', Anchor.onAnchorClick.bind(this));
	}

	static onAnchorClick(e) {
		const href = e.delegateTarget.getAttribute('href');

		if (href && href.indexOf('#') === 0) {
			e.preventDefault();
			const section = document.querySelector(href);
			const top = getOffset(section).top;
			scrollTo(top);
		}
	}
}

export default Anchor;
