import moment from 'moment';

import templateDay from './calendarday';

export default (days, {dateTime, linkTarget}) => {
	const now = moment();
	const from = moment(dateTime.from);
	const to = moment(dateTime.to);

	const toFormat = now.year() !== to.year() ? 'DD.MM.YY' : 'DD.MM.';

	return `
		<h3 class="phaeno-calendarSimple__head">
			<span class="phaeno-calendarSimple__head--fromto">${from.format('DD.MM.')} - ${to.format(toFormat)}</span>
			<span class="phaeno-calendarSimple__head--week">KW ${moment(dateTime.request).format('W')}</span>
		</h3>

		${days.length ? `
			<ul class="phaeno-calendar__month">
				${days.map(day => templateDay({data: day, linkTarget: linkTarget})).join('')}
			</ul>
		` : `
			<p>Keine Termine</p>
		`}
	`;
};
