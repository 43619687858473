class CalendarWeeksMenu {
	constructor() {
		this.nextBtnClass = '.js-phaeno-calendarSimple__next';
		this.prevBtnClass = '.js-phaeno-calendarSimple__prev';
	}

	init() {
		this.nextBtn = document.body.querySelector(this.nextBtnClass);
		this.prevBtn = document.body.querySelector(this.prevBtnClass);
	}

	updateData(metadata) {
		this.metadata = metadata;
		this.links = metadata.links;

		if (this.links.next.length > 8) {
			this.showNextBtn();
			this.updateNextLink(this.links.next);
		} else {
			this.hideNextBtn();
		}

		if (this.links.previous.length > 8) {
			this.showPrevBtn();
			this.updatePrevLink(this.links.previous);
		} else {
			this.hidePrevBtn();
		}
	}

	updateNextLink(jsonPath) {
		this.nextBtn.setAttribute('data-url', jsonPath);
	}

	updatePrevLink(jsonPath) {
		this.prevBtn.setAttribute('data-url', jsonPath);
	}

	showNextBtn() {
		this.nextBtn.style.visibility = 'visible';
	}

	hideNextBtn() {
		this.nextBtn.style.visibility = 'hidden';
	}

	showPrevBtn() {
		this.prevBtn.style.visibility = 'visible';
	}

	hidePrevBtn() {
		this.prevBtn.style.visibility = 'hidden';
	}
}

export default CalendarWeeksMenu;
