//
// Menu that behaves as next prev on mobiles and as tab menu for bigger screens.
//
import TabMenu from '../tabmenu/TabMenu';
import variables from '../../utils/variables';

import NextPrevMenu from './NextPrevMenu';

class NextPrevTabMenu {
	//
	// IMPORTANT: if module contains another 'next prev' or 'tab' module
	// all module's element (js-phaeno-nextPrevTabMenu, js-phaeno-nextPrevMenu__button,
	// js-phaeno-tabMenu__button, js-phaeno-tabMenu__content js-phaeno-nextPrevMenu__content)
	// need to have a 'data-phaeno-menu-id' data added with the same String.
	// If not, it can be skipped.
	//

	constructor(container) {
		this.container = container;
		this.currentNo = 0;
	}

	init() {
		this.nextPrevMenu = new NextPrevMenu(this.container);
		this.nextPrevMenu.init();
		this.nextPrevMenu.setActiveByNo(this.currentNo);
		this.nextPrevMenu.addListener(variables.events.NEXT_PREV_MENU_CHANGE, this.onNextPrevChange.bind(this));

		this.tabMenu = new TabMenu(this.container);
		this.tabMenu.init();
		this.tabMenu.setIsAlwaysOpen(true);
		this.tabMenu.addListener(variables.events.TAB_MENU_CHANGE, this.onTabChange.bind(this));
	}

	onNextPrevChange() {
		const current = this.nextPrevMenu.getCurrentId();
		this.tabMenu.setCurrent(current);
	}

	onTabChange() {
		const current = this.tabMenu.getCurrentId();
		this.nextPrevMenu.setCurrent(current);
	}
}

export default NextPrevTabMenu;
