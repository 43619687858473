import {EventEmitter} from 'events';

import delegate from 'delegate';

import variables from '../../utils/variables';

const CLASS_DAY = 'js-phaeno-calendar__day';
const CLASS_ACTIVE = 'phaeno-calendar__day--active';

class CalendarDayOpener extends EventEmitter {
	init(isSimple = false) {
		if (!isSimple) {
			delegate(document.body, `.${CLASS_DAY}`, 'click', this.onClick.bind(this), false);
		}
		//delegate(document.body, `.${CLASS_DAY}`, 'mouseover', this.onOver.bind(this), false);
		this.currentDay = document.body.querySelector('.' + CLASS_ACTIVE);
	}

	onClick(e) {
		this.activateDayElement(e.delegateTarget);
	}

	activateDayElement(elem) {
		this.closeAll();

		if (elem !== this.currentDay) {
			elem.classList.add(CLASS_ACTIVE);
			this.currentDay = elem;
		} else {
			this.currentDay = null;
		}
	}

	activateDayByDateString(dateString) {
		const days = document.body.querySelectorAll(`.${CLASS_DAY}`);
		const day = findNodeListItem(days, elem => elem.dataset.dateString === dateString);
		if (day) {
			this.activateDayElement(day);
		}
		return day;
	}

	onOver(e) {
		if (e.delegateTarget !== this.currentDay) {
			this.closeAll();
			e.delegateTarget.classList.add(CLASS_ACTIVE);
			this.currentDay = e.delegateTarget;
		}
	}

	closeAll() {
		const openDays = document.body.querySelectorAll('.' + CLASS_ACTIVE);

		for (let i = 0; i < openDays.length; i++) {
			const el = openDays[i];
			el.classList.remove(CLASS_ACTIVE);
		}

		this.emit(variables.events.DAY_CLOSED);
	}
}

function findNodeListItem(nodeList, f) {
	for (let i = 0; i < nodeList.length; i += 1) {
		const elem = nodeList[i];
		if (f(elem)) {
			return elem;
		}
	}
	return null;
}

export default CalendarDayOpener;
