import variables from '../../utils/variables';

class EventsPositioner {
	static position() {
		this.FROM = variables.calendar.from;
		this.TO = variables.calendar.to;
		this.DURATION = this.TO - this.FROM;
		this.HOURWIDTH = 100 / this.DURATION * 0.999;

		const events = document.querySelectorAll('.js-phaeno-calendar__eventElement');

		for (let i = 0; i < events.length; i++) {
			const e = events[i];

			const start = new Date(e.dataset.start);
			const end = new Date(e.dataset.end);
			let startHours = start.getHours() + start.getMinutes() / 60;
			let endHours = end.getHours() + end.getMinutes() / 60;

			//each event, which starts after 18, should be desplayed
			//as starting at 18 with an hour DURATION
			startHours = Math.min(startHours, this.TO - 1);
			endHours = Math.min(endHours, this.TO );

			if (startHours && endHours) {
				e.style.left = (startHours - this.FROM) * this.HOURWIDTH + '%';
				e.style.width = (endHours - startHours) * this.HOURWIDTH + '%';
			}
		}
	}
}

export default EventsPositioner;
