class Luma {
	constructor(container) {
		this.container = container;
		this.interval = null;
		this.currentImage = 1;
		this.imagecount = 4;
		this.imagesequence = [1, 2, 3, 4, 3, 2];
		this.framerate = 10;
	}

	init() {
		this.addListeners();
		//if ('DeviceMotionEvent' in window) {
			//window.addEventListener('devicemotion', this.onDeviceMotion.bind(this), false);
		//} else {
			this.startAnim();
		//}
	}

	addListeners() {
		this.listeners = [];
		this.listeners.push(this.container.addEventListener('mousemove', this.onMove.bind(this)));
		this.listeners.push(this.container.addEventListener('mouseenter', this.onEnter.bind(this)));
		this.listeners.push(this.container.addEventListener('mouseleave', this.onLeave.bind(this)));
		/*
		if ('DeviceMotionEvent' in window) {
			this.listeners.push(window.addEventListener('devicemotion', this.onDeviceMotion.bind(this)));
		}
		*/
	}

	showImage(num) {
		const newnum = num % this.imagesequence.length + 1;
		for (let i = 1; i <= this.imagecount; i++) {
			this.container.classList.remove('phaeno-luma__image--img' + i);
		}
		this.container.classList.add('phaeno-luma__image--img' + this.imagesequence[newnum - 1]);
	}

	nextImage() {
		this.showImage(this.currentImage++);
	}

	startAnim() {
		this.currentImage = 1;
		this.interval = setInterval(this.nextImage.bind(this), 1000 / this.framerate);
	}

	stopAnim() {
		this.currentImage = 1;
		clearInterval(this.interval);
	}

	onMove(e) {
		const ratio = e.offsetX / this.container.offsetWidth;
		const imageNumber = Math.floor(ratio * this.imagecount);
		this.showImage(imageNumber);
	}

	onEnter() {
		this.stopAnim();
	}

	onLeave() {
		this.startAnim();
	}

	onDeviceMotion(e) {
		const orientation = e.accelerationIncludingGravity.x && e.accelerationIncludingGravity.x.toFixed(3);
		let num = Math.floor(((orientation * 2 + 9.81) / 5));
		num = Math.max(Math.min(num, this.imagecount-1), 0);
		console.log('num', num);
		this.showImage(num);
	}
}

export default Luma;
