import Bookmark from './Bookmark';

class Bookmarks {
	constructor(heightChecker) {
		this.heightChecker = heightChecker;
	}

	init() {
		this.bookmarksArr = [];
		this.bookmarks = document.querySelectorAll('.js-phaeno-bookmark');
		for(let i=0; i<this.bookmarks.length; i++) {
			this.bookmark = new Bookmark(this.bookmarks[i], this.bookmarks[i+1], this.heightChecker);
			this.bookmark.init();
			this.bookmarksArr.push(this.bookmark);
		}
	}
}

export default Bookmarks;
