export default function tpl(data) {
	return `
	<li class="phaeno-calendarEvent js-phaeno-calendarEvent">
		<div class="phaeno-calendarEvent__content js-phaeno-calendarEvent__content">
			<button class="phaeno-buttonClose phaeno-calendarEvent__close js-phaeno-calendarEvent__close"><span><svg><use xlink:href="#close_small"></use></svg></span></button>

			<div class="phaeno-containerThirds">
				<div class="phaeno-containerThirds__item2-3">
					<h3 class="phaeno-calendarEvent__head">${data.name}</h3>
					<p class="phaeno-calendarEvent__copy">${data.description}</p>

					<table class="phaeno-calendarEvent__table">
						${data.dateDescription ? `
						<tr class="phaeno-calendarEvent__tableRow">
							<td class="phaeno-calendarEvent__textName">Datum</td>
							<td class="phaeno-calendarEvent__textValue">${data.dateDescription}</td>
						</tr>` : ''}

						${data.timeDescription ? `
						<tr class="phaeno-calendarEvent__tableRow">
							<td class="phaeno-calendarEvent__textName">Zeit</td>
							<td class="phaeno-calendarEvent__textValue">${data.timeDescription}</td>
						</tr>` : ''}

						${data.location ? `
						<tr class="phaeno-calendarEvent__tableRow">
							<td class="phaeno-calendarEvent__textName">Ort</td>
							<td class="phaeno-calendarEvent__textValue">${data.location}</td>
						</tr>` : ''}

						${data.price ? `
						<tr class="phaeno-calendarEvent__tableRow">
							<td class="phaeno-calendarEvent__textName">Eintrittpreis</td>
							<td class="phaeno-calendarEvent__textValue">${data.price}</td>
						</tr>` : ''}
					</table>
				</div>

				<div class="phaeno-containerThirds__item1-3">
					${eventDescriptionImage(data)}
				</div>
			</div>
		</div>
	</li>
	`;
}

function eventDescriptionImage(data) {
	if (!data.images || !data.images[0]) {
		return '';
	}

	const img = data.images[0];

	return `
		<img
			class="phaeno-image"
			src="${img.thumbnails.teaser.url}"
			alt="${img.alternative}"
		/>
	`;
}
