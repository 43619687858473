import getScrollPositionY from '../../utils/getScrollPositionY';

class PageScrolled {
	// Adds a class on body when scroll position is not zero.
	// Used for example to hide slogan when page is not on top.
	init() {
		this.scrolledClass = 'scrolled';
		this.onScrollHandler = this.onScroll.bind(this);
		window.addEventListener('scroll', this.onScrollHandler);
	}

	onScroll(e) {
		this.scrollPos = getScrollPositionY();

		if (this.scrollPos > 0) {
			document.body.classList.add(this.scrolledClass);
		} else {
			document.body.classList.remove(this.scrolledClass);
		}
	}
}

export default PageScrolled;
