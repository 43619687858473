import httpRequest from 'superagent';

import variables from '../../utils/variables';
import startsWith from '../../utils/startsWith';
import {scrollToFixNav} from '../../utils/scrollTo';

import templateMonth from './templates/calendarmonth';
import templateWeek from './templates/calendarweek';
import templateLoader from './templates/calendarloader';
import templateEmpty from './templates/calendarempty';
import templateError from './templates/calendarerror';
import CalendarJsonRebuilder from './CalendarJsonRebuilder';
import EventsPositioner from './EventsPositioner';
import CalendarDayOpener from './CalendarDayOpener';
import CalendarEventDescription from './CalendarEventDescription';
import CalendarTimeLineMark from './CalendarTimeLineMark';
import CalendarUpdateLinks from './CalendarUpdateLinks';
import CalendarWeeksMenu from './CalendarWeeksMenu';


const HASH_PREFIX = '#calendar_';
const CLASS_WEEK_VIEW = 'phaeno-calendar--simple';

class Calendar {
	constructor(container) {
		this.container = container;
	}

	init() {
		this.isWeekView = this.container.classList.contains(CLASS_WEEK_VIEW);

		this.calendarEventDescription = new CalendarEventDescription();
		this.calendarEventDescription.init();

		this.calendarDayOpener = new CalendarDayOpener();
		this.calendarDayOpener.init(this.isWeekView);
		this.calendarDayOpener.on(variables.events.DAY_CLOSED, this.onDayClosed.bind(this));

		this.calendarTimeLineMark = new CalendarTimeLineMark();

		this.calendarUpdateLinks = new CalendarUpdateLinks();
		this.calendarUpdateLinks.init();
		this.calendarUpdateLinks.on(variables.events.CALENDAR_UPDATE, this.onCalendarUpdate.bind(this));

		this.calendarJsonRebuilder = new CalendarJsonRebuilder();
		this.calendarJsonRebuilder.init(this.container.dataset.dictionaryurl);
		this.calendarJsonRebuilder.on(variables.events.DICTIONARY_READY, this.onDictionaryReady.bind(this));

		if (this.isWeekView) {
			this.calendarWeeksMenu = new CalendarWeeksMenu();
			this.calendarWeeksMenu.init();
		}
	}

	onDictionaryReady() {
		this.defaultJsonUrl = this.container.dataset.url;
		if (this.defaultJsonUrl) {
			this.loadData(this.defaultJsonUrl);
		}
	}

	loadData(url) {
		this.destroyContent();
		this.appendHtml(templateLoader());
		this.url = url;

		httpRequest
			.get(this.url)
			.end((error, response) => {
				if (error || !response.ok) {
					console.error(error);
					this.destroyContent();
					this.appendHtml(templateError());
				} else {
					this.onData(response.text);
				}
			});
	}

	onData(response) {
		const json = JSON.parse(response);

		const currentMonth = (() => {
			const REGEX = /tx_nnphaenoevents_events%5Byear%5D=([0-9]+)&tx_nnphaenoevents_events%5Bmonth%5D=([0-9]+)/;
			const match = REGEX.exec(this.url);
			if (match !== null) {
				const date = new Date();
				date.setUTCFullYear(match[1]);
				date.setUTCMonth(match[2] - 1);
				date.setUTCDate(1);
				date.setUTCHours(0, 0, 0, 0);
				return date;
			} else {
				const date = new Date();
				date.setUTCDate(1);
				date.setUTCHours(0, 0, 0, 0);
				return date;
			}
		})();
		const months = this.calendarJsonRebuilder.rebuild(json, currentMonth);

		this.destroyContent();

		if (this.isWeekView) {
			const allDays = [].concat.apply([], months.map(month => month.days));
			const metadata = Object.assign(
				{},
				json._metadata,
				{
					linkTarget: this.container.dataset.linkTarget,
				}
			);
			this.appendHtml(templateWeek(allDays, metadata));
		} else {
			this.appendHtml(months.map(templateMonth).join('') || templateEmpty());
		}

		this.calendarEventDescription.init(json.events);
		this.calendarTimeLineMark.init();
		EventsPositioner.position();

		if (this.isWeekView) {
			this.calendarWeeksMenu.updateData(json._metadata);
		}

		const hash = window.location.hash;
		if (startsWith(hash, HASH_PREFIX)) {
			const dateString = hash.substring(HASH_PREFIX.length);
			const dayElem = this.calendarDayOpener.activateDayByDateString(dateString);
			scrollToFixNav(dayElem || this.container);
		}
	}

	appendHtml(html) {
		this.container.insertAdjacentHTML('beforeend', html);
	}

	destroyContent() {
		this.container.innerHTML = '';
	}

	onDayClosed() {
		this.calendarEventDescription.hideAll();
	}

	onCalendarUpdate(url) {
		this.loadData(url);
	}
}

export default Calendar;
