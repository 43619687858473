import ParallaxElement from './ParallaxElement';

class ParallaxElements {
	constructor(container) {
		this.container = container;
	}

	init() {
		if (!this.container) {
			return;
		}

		this.el = this.container.querySelectorAll('.js-phaeno-parallaxElement');
		this.elArr = [];

		for (let i = 0; i < this.el.length; i++) {
			const e = new ParallaxElement(this.el[i]);
			this.elArr.push(e);
		}
	}

	deinit() {
		for (let i = 0; i < this.elArr.length; i++) {
			this.elArr[i].deinit();
		}
	}
}

export default ParallaxElements;
