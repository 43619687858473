import ParallaxElements from './ParallaxElements';
import ShowOnScrollElements from './ShowOnScrollElements';

class Animations {
	constructor(container) {
		this.container = container;
	}

	init() {
		this.showOnScrollElements = new ShowOnScrollElements(this.container);
		this.showOnScrollElements.init();

		this.parallaxElements = new ParallaxElements(this.container);
		this.parallaxElements.init();

		// set initial animation styles via javascript
		const sheet = document.createElement('style');
		sheet.innerHTML = '.phaeno-showAlpha {opacity: 0;} .phaeno-showAlphaUp {opacity: 0; transform: translate(0, 50px);} .phaeno-showAlphaDown {opacity: 0; transform: translate(0, -50px);}';
		document.body.appendChild(sheet);
	}

	deinit() {
		if (this.showOnScrollElements) {
			this.showOnScrollElements.deinit();
		}
		if (this.parallaxElements) {
			this.parallaxElements.deinit();
		}
	}
}

export default Animations;
