import ShowOnScrollElement from './ShowOnScrollElement';

class ShowOnScrollElements {
	constructor(container) {
		this.container = container;
	}

	init() {
		if (!this.container) {
			return;
		}

		this.el = this.container.querySelectorAll('.js-phaeno-showOnScroll');

		this.elArr = [];
		for (let i = 0; i < this.el.length; i++) {
			const e = new ShowOnScrollElement(this.el[i]);
			this.elArr.push(e);
			e.init();
		}
	}

	deinit() {
		for (let i = 0; i < this.elArr.length; i++) {
			this.elArr[i].deinit();
		}
	}
}

export default ShowOnScrollElements;
